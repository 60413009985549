import React, { useRef, useState } from "react"
import { Page } from "../../shared/Page/Page"
import Helmet from "react-helmet"
import styled from "styled-components"
import { PageHeader } from "../../shared/PageHeader/PageHeader"
import { FormLabel, H3 } from "../../shared/Ui/Typography/Typography"
import { TextInput } from "../../shared/Inputs/TextInput"
import { FlatButton } from "../../shared/Ui/FlatButton/FlatButton"
import { Header } from "../../shared/Header/Header"
import { Footer } from "../../shared/Footer/Footer"
import { SelectInput, SelectOption } from "../../shared/Inputs/SelectInput"
import { TextAreaInput } from "../../shared/Inputs/TextAreaInput"
import media from "css-in-js-media"

const Container = styled.div`
    section.content {
        display: flex;
        padding: 40rem 0 100rem;
        margin-top: 20rem;
        background: linear-gradient(
            ${({ theme }) => theme.surfaces.light},
            #f8fafc
        );
        box-shadow: ${({ theme }) => theme.surfaces.dark} 0 -50rem 50rem -50px
            inset;

        > .container {
            flex: 1;
            display: flex;
            gap: 20rem;
            justify-content: center;

            > .left {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0 40rem 0 0;

                .form {
                    padding: 30rem 40rem;
                    border: 1rem solid ${({ theme }) => theme.borders.light};
                    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
                    background: ${({ theme }) => theme.surfaces.lighter};
                    border-radius: 10rem;

                    h3 {
                        margin: 0 0 20rem;
                    }

                    .error {
                        background: #fff1f2;
                        color: #be123c;
                        padding: 15rem 20rem;
                        border-radius: 10rem;
                        font-size: 14rem;
                    }
                }

                .input-list {
                    display: flex;
                    flex-direction: column;
                    gap: 20rem;
                    max-width: 500rem;

                    > div {
                        display: flex;
                        flex-direction: row;
                        align-items: flex-start;
                        gap: 20rem;

                        > label {
                            margin-top: 12rem;
                            width: 100rem;
                        }

                        > input {
                            flex: 1;
                        }
                    }
                }
            }

            > .right {
                flex: 1;
                display: flex;
                flex-direction: column;
                border-left: 1rem solid ${({ theme }) => theme.borders.normal};
                padding: 0 0 0 40rem;
                color: ${({ theme }) => theme.text.light};
                max-width: 300rem;

                p {
                    line-height: 30rem;
                    margin: 0 0 5rem;
                }

                .other {
                    display: flex;
                    flex-direction: column;
                    gap: 5rem;

                    .option-row {
                        display: flex;
                        gap: 20rem;
                        padding: 10rem 15rem;
                        margin: 0 -10rem;
                        cursor: pointer;
                        border-radius: 5rem;
                        transition: all ease-in-out 150ms;
                        box-shadow: rgba(149, 157, 165, 0.2) 0 0 0;

                        &:hover {
                            box-shadow: rgba(149, 157, 165, 0.2) 0 8rem 24rem;
                            background: ${({ theme }) =>
                                theme.surfaces.lighter};
                        }

                        > div {
                            &:first-of-type {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: ${({ theme }) => theme.primary};
                            }

                            &:last-of-type {
                                display: flex;
                                flex-direction: column;
                                gap: 5rem;

                                .title {
                                    color: ${({ theme }) => theme.text.normal};
                                    font-family: "Gilroy-Regular", sans-serif;
                                }
                            }
                        }
                    }
                }
            }

            ${media("<tablet")} {
                flex-direction: column-reverse;
                gap: 0;
                padding: 0;
                margin-top: -40rem;

                > .right {
                    border-left: 0;
                    max-width: unset;
                    padding: 10rem 20rem 20rem;
                    background: ${({ theme }) => theme.surfaces.light};
                    border-bottom: 1rem solid
                        ${({ theme }) => theme.borders.light};

                    .other {
                        flex-wrap: wrap;
                        justify-content: center;
                        gap: 10rem;

                        > * {
                            flex: 1;
                        }
                    }
                }

                > .left {
                    padding: 0;

                    > div {
                        width: 100%;
                    }

                    .form {
                        border: none;
                        width: 100%;
                        box-shadow: none;
                        border-radius: 0;
                        padding: 30rem 20rem;

                        .input-list {
                            max-width: unset;
                        }
                    }
                }
            }
        }
    }
`

enum STATE {
    READY,
    SUBMITTING,
    SUBMITTED,
}

const InquiryTypes: Array<SelectOption> = [
    {
        id: 0,
        label: "Please select an option",
        disabled: true,
    },
    {
        id: 1,
        label: "Sales",
    },
    {
        id: 2,
        label: "Support",
    },
    {
        id: 5,
        label: "Startup program pricing",
    },
    {
        id: 3,
        label: "Fraud and spam reports",
    },
    {
        id: 4,
        label: "Partnerships",
    },
    {
        id: 5,
        label: "Careers",
    },
    {
        id: 6,
        label: "Other",
    },
]

const ContactForm = () => {
    const inputFirstNameRef = useRef(null)
    const inputLastNameRef = useRef(null)
    const inputEmailRef = useRef(null)
    const inputMessageRef = useRef(null)
    const [state, setState] = useState<STATE>(STATE.READY)
    const [inquiryType, setInquiryType] = useState(0)
    const [formError, setFormError] = useState("")

    const onSubmit = (event) => {
        event.preventDefault()

        setFormError("")

        const values = {
            firstName: inputFirstNameRef.current.value.trim(),
            lastName: inputLastNameRef.current.value.trim(),
            email: inputEmailRef.current.value.trim(),
            message: inputMessageRef.current.value.trim(),
            inquiryType,
        }

        if (Object.values(values).includes("") || values.inquiryType === 0) {
            setFormError("Please fill out the form completely")
            return
        }

        setState(STATE.SUBMITTING)

        const formData = new FormData()
        formData.append("first_name", values.firstName)
        formData.append("last_name", values.lastName)
        formData.append("email", values.email)
        formData.append(
            "type",
            `${
                InquiryTypes.find((t) => t.id === values.inquiryType).label ??
                "Unknown"
            }`
        )
        formData.append("message", values.message)

        fetch("https://getform.io/f/e941eeb0-1090-417f-b733-efb35a5166be", {
            method: "POST",
            body: formData,
        }).then(() => {
            setState(STATE.SUBMITTED)
        })
    }

    return (
        <div>
            {[STATE.READY, STATE.SUBMITTING].includes(state) && (
                <form onSubmit={onSubmit}>
                    <div className={"input-list"}>
                        {formError && (
                            <div className={"error"}>{formError}</div>
                        )}
                        <div>
                            <FormLabel>First name</FormLabel>
                            <TextInput
                                placeholder={"John"}
                                size={"larger"}
                                ref={inputFirstNameRef}
                            />
                        </div>

                        <div>
                            <FormLabel>Last name</FormLabel>
                            <TextInput
                                placeholder={"Smith"}
                                size={"larger"}
                                ref={inputLastNameRef}
                            />
                        </div>

                        <div>
                            <FormLabel>Email address</FormLabel>
                            <TextInput
                                placeholder={"john.smith@company.com"}
                                size={"larger"}
                                ref={inputEmailRef}
                            />
                        </div>

                        <div>
                            <FormLabel style={{ marginTop: "10rem" }}>
                                Inquiry type
                            </FormLabel>
                            <SelectInput
                                value={inquiryType}
                                options={InquiryTypes}
                                onChange={(newId) => {
                                    setInquiryType(newId)
                                }}
                            />
                        </div>

                        <div>
                            <FormLabel>Your message</FormLabel>
                            <TextAreaInput
                                placeholder={"Dear team..."}
                                size={"larger"}
                                rows={10}
                                ref={inputMessageRef}
                            />
                        </div>

                        <div style={{ justifyContent: "flex-end" }}>
                            <FlatButton
                                color={"primary"}
                                type={"submit"}
                                variant={"outlined"}
                                size={"larger"}
                                disabled={state !== STATE.READY}
                            >
                                {state === STATE.SUBMITTING
                                    ? "Submitting..."
                                    : "Send message"}
                            </FlatButton>
                        </div>
                    </div>
                </form>
            )}
            {state === STATE.SUBMITTED && (
                <div className={"submitted"}>
                    <p>
                        Thank you! Your message has been sent, we'll reply to
                        you as soon as possible the address provided.
                    </p>
                </div>
            )}
        </div>
    )
}

const Contact = ({ location }) => {
    return (
        <Page
            disableCta
            disableContentBounds
            disableHeader
            disableFooter
            location={location}
        >
            <Helmet>
                <title>Contact us</title>
            </Helmet>
            <Container>
                <Header constrained />
                <PageHeader
                    h1={"Contact us"}
                    h2={"We've got a range of ways to get in touch"}
                    className={"pageConstrained"}
                />
                <section className={"content overlapPageMargins"}>
                    <div className={"container pageConstrained"}>
                        <div className={"left"}>
                            <div>
                                <div className={"form"}>
                                    <H3>Send us a message</H3>
                                    <ContactForm />
                                </div>
                            </div>
                        </div>
                        <div className={"right"}>
                            <H3>Other methods</H3>
                            <p>
                                Need to speak to a human quick? Try one of the
                                following options.
                            </p>
                            <div className={"other"}>
                                <div
                                    className={"option-row"}
                                    onClick={() => Intercom("show")}
                                >
                                    <div className={"icon"}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="24px"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            fill="currentColor"
                                        >
                                            <path d="M20 6h-1v8c0 .55-.45 1-1 1H6v1c0 1.1.9 2 2 2h10l4 4V8c0-1.1-.9-2-2-2zm-3 5V4c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v13l4-4h9c1.1 0 2-.9 2-2z" />
                                        </svg>
                                    </div>
                                    <div>
                                        <div className={"title"}>Live chat</div>
                                        <div className={"description"}>
                                            In browser
                                        </div>
                                    </div>
                                </div>

                                <a href={"tel:+611800951343"}>
                                    <div className={"option-row"}>
                                        <div className={"icon"}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                width="24px"
                                                fill="currentColor"
                                            >
                                                <path d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24 1.12.37 2.33.57 3.57.57.55 0 1 .45 1 1V20c0 .55-.45 1-1 1-9.39 0-17-7.61-17-17 0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1 0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2z" />
                                            </svg>
                                        </div>
                                        <div>
                                            <div className={"title"}>
                                                Call us
                                            </div>
                                            <div className={"description"}>
                                                1800 951 343
                                            </div>
                                        </div>
                                    </div>
                                </a>

                                <a href={"sms:+61480032023"}>
                                    <div className={"option-row"}>
                                        <div className={"icon"}>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                height="24px"
                                                viewBox="0 0 24 24"
                                                width="24px"
                                                fill="currentColor"
                                            >
                                                <path d="M20 2H4c-1.1 0-2 .9-2 2v18l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z" />
                                            </svg>
                                        </div>
                                        <div>
                                            <div className={"title"}>
                                                Text us anytime
                                            </div>
                                            <div className={"description"}>
                                                0480 032 023
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer constrained />
            </Container>
        </Page>
    )
}

export default Contact
