import { Listbox } from "@headlessui/react"
import React, { useMemo } from "react"
import { FlatButton } from "../Ui/FlatButton/FlatButton"
import styled from "styled-components"

const DropDown = styled.div`
    position: absolute;
    top: calc(100% + 10rem);
    width: 100%;
    border-radius: 10rem;
    background: ${({ theme }) => theme.surfaces.lighter};
    box-shadow: rgba(17, 12, 46, 0.15) 0 48rem 100rem 0;
    padding: 10rem 13rem;

    li {
        list-style: none outside none;
        padding: 10rem 15rem;
        margin-bottom: 1rem;
        cursor: pointer;
        transition: all ease-in-out 150ms;

        color: ${({ theme }) => theme.text.normal};

        &[aria-disabled="true"] {
            color: ${({ theme }) => theme.text.lighter};
            pointer-events: none;
        }

        &:hover {
            background: ${({ theme }) => theme.surfaces.light};
        }
    }
`

const Container = styled.div`
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
`

export type SelectOption = {
    id: number
    label: JSX.Element | string
    data?: any
    disabled?: boolean
}

interface SelectInputProps {
    value?: number
    options: Array<SelectOption>
    onChange: (id: number, option: SelectOption) => void
}

export const SelectInput = ({ value, options, onChange }) => {
    const selectedOption: SelectOption = useMemo(() => {
        return options.find((o) => o.id == value) ?? options[0]
    }, [value, options])

    return (
        <Container>
            <Listbox
                value={value}
                onChange={(newValue) =>
                    onChange(
                        parseInt(newValue),
                        options.find((o) => o.id == newValue)
                    )
                }
            >
                <Listbox.Button
                    as={FlatButton}
                    variant={"outlined"}
                    color={"default"}
                    size={"large"}
                    fullWidth
                >
                    {selectedOption.label}
                </Listbox.Button>
                <Listbox.Options as={DropDown}>
                    {options.map((option) => (
                        <Listbox.Option
                            key={option.id}
                            value={option.id}
                            disabled={option.disabled}
                        >
                            {option.label}
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </Listbox>
        </Container>
    )
}
