import React from "react"

import styled from "styled-components"

interface TextInputProps {
    size?: "small" | "normal" | "large" | "larger"
}

export const TextInput = styled.input<TextInputProps>`
    border: 1px solid ${({ theme }) => theme.borders.light};
    border-radius: 5rem;
    outline: none;

    color: ${({ theme }) => theme.text.normal};

    &:focus {
        border-color: ${({ theme }) => theme.primary};
    }

    ::placeholder {
        color: ${({ theme }) => theme.text.lighter};
    }

    ${({ size = "normal" }) =>
        size === "normal" &&
        `
        padding: 5rem 10rem;
        font-size: 14rem;
    `}

    ${({ size = "normal" }) =>
        size === "large" &&
        `
        padding: 7rem 14rem;
        font-size: 16rem;
    `}

    ${({ size = "normal" }) =>
        size === "larger" &&
        `
        padding: 10rem 16rem;
        font-size: 18rem;
    `}
`
